<template>
    <div class="page">
    </div>
  </template>
  
  <script>
  // import 'assets/css/style.css'
  
  export default {
    data() {
      return {
        // headTitle: "岳阳南湖新区新时代文明",
      };
    },
    components: {
    },
    computed: {},
    created() {
   
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style scoped>
  .page {
    background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/carInc.jpg) no-repeat center;
    background-size: 100% 100%;
  }
  </style>